import { Controller } from "stimulus"
import Player from '@vimeo/player';

export default class extends Controller {

  connect() {
    $('.btn-continue').attr('disabled', 'disabled')

    var iframe = document.querySelector('iframe');
    var player = new Player(iframe);
    let videoController = this

    player.on('ended', function() {
      console.log('Played the video');
      videoController.enableContinue()
    });
  }

  enableContinue() {
    let continueBtn = document.querySelector('.btn-continue')
    continueBtn.removeAttribute('disabled')
  }
}