import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  initialize() {
  }

  connect() {
    var svgContainer = document.querySelector('.complete-animation');
    var animItem = lottie.loadAnimation({
      wrapper: svgContainer,
      animType: 'svg',
      loop: false,
      autoplay: true,
      path: '/chapter-complete.json'
    });

    animItem.addEventListener('DOMLoaded', function() {
      animItem.play();
    })
  }

}