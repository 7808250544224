import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  static targets = [ "user" ]

  connect() {
    this.trackContinue()
  }

  trackContinue() {
    if (this.userTargets.every(this.checkForPlay)) {
      this.enableContinue()
    } else {
      this.disableContinue()
    }
  }

  checkForPlay(element) {
    return element.classList.contains('status-completed')
  }

  enableContinue() {
    let continueBtn = document.querySelector('.btn-continue')
    continueBtn.removeAttribute('disabled')
    continueBtn.classList.remove('disabled')
  }

  disableContinue() {
    let continueBtn = document.querySelector('.btn-continue')
    continueBtn.classList.add('disabled')
    continueBtn.setAttribute('disabled', true)
  }
}