import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  static targets = [ "choice" ]

  connect() {
    this.validateForm()
    this.$response_modal = $('#response_modal')
    let assessmentController = this
    this.$response_modal.on('show.bs.modal', function () { assessmentController.swapModalText() })
  }

  checkForEmpty(element) {return element.checked}

  validateForm() {
    if (this.choiceTargets.every(this.checkForEmpty)) {
      this.enableContinue()
    } else {
      this.disableContinue()
    }
  }

  showResponse() {
    this.$response_modal.modal({ backdrop: false })
    this.enableContinue()
  }

  swapModalText() {
    let modalText = event.currentTarget.getAttribute('data-response')
    this.$response_modal.find('.modal-body').text(modalText)
  }

  disableContinue() {
    $('.btn-continue').attr('disabled', 'disabled')
  }

  enableContinue() {
    $('.custom-control-input').on('click', function(e) {
      $('.continue-btn').find('input[name="answer"]').val(e.target.value)
      $('.btn-continue').attr('disabled', false)
    })
  }
}