import { Controller } from "stimulus"
import $ from 'jquery'
import slick from 'slick-carousel'

export default class extends Controller {

  static targets = [ "" ]

  connect() {
    this.disableContinue()

    $('.scenario_slideshow').slick({
      dots: true,
      speed: 500,
      infinite: false,
      prevArrow: '<button type="button" class="btn btn-prev">Previous</button>',
      nextArrow: '<button type="button" class="btn btn-next">Next</button>'
    });

    const scenarioController = this
    let lastSlide = false

    $('.scenario_slideshow').on('afterChange', function(event, slick, currentSlide, nextSlide) {
      console.log(currentSlide, nextSlide);

      if (currentSlide == 2) {
        lastSlide = true
        scenarioController.enableContinue()
        scenarioController.disableNext()
      }
      if (currentSlide != 2) {
        scenarioController.enableNext()
      }
    });

  }

  get dataPath() {
    return this.data.get('next-path')
  }

  setNextButton() {
    window.location = this.dataPath
  }

  disableContinue() {
    let continueBtn = document.querySelector('.btn-continue')
    continueBtn.classList.add('disabled')
    continueBtn.setAttribute('disabled', true)
  }

  disableNext() {
    let continueBtn = document.querySelector('.btn-next')
    continueBtn.classList.add('disabled')
    continueBtn.setAttribute('disabled', true)
  }

  enableNext() {
    let continueBtn = document.querySelector('.btn-next')
    continueBtn.classList.remove('disabled')
    continueBtn.removeAttribute('disabled')
  }

  enableContinue() {
    let continueBtn = document.querySelector('.btn-continue')
    continueBtn.removeAttribute('disabled')
    continueBtn.classList.remove('disabled')
  }
}