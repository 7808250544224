// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// const $ = require("jquery");
import $ from 'jquery'
import popper from "popper.js"
window.Popper = popper

// bootstrap
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// src/application.js
import "classlist-polyfill"
import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /\.js$/)
application.load(definitionsFromContext(context))

import lottie from "lottie-web/build/player/lottie.min"
window.lottie = lottie

// TODO: move to assign_value_controller.js
$(function() {
  $('.custom-control-input').on('click', function(e) {
    $('.continue-btn').find('input[name="answer"]').val(e.target.value)
  })

  $('[data-redirect]').click(function (e) {
    window.open(e.target.href, '_blank');
    window.location = e.target.dataset.redirect;
  })
})