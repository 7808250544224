import { Controller } from "stimulus"
import Player from '@vimeo/player';

export default class extends Controller {

  static targets = [ "choiceInput" ]

  initialize() {
    this.continueBtn = document.querySelector('.btn-continue')
    this.initPopovers()
  }

  connect() {
    this.initPlayer()
    this.validateContinue()
  }

  initPlayer() {
    var iframe = document.querySelector('iframe');
    var player = new Player(iframe);
    const videoController = this

    player.on('ended', function() {
      videoController.enabledChoice()
    });
  }

  initPopovers() {
    const videoController = this
    this.$popovers = $('[data-toggle="popover"]')
    this.$popovers.popover({
      container: '.answer-list-container',
      delay: { "show": 1000 }
    })

    this.$popovers.on('shown.bs.popover', function () {
      videoController.validateContinue()
      videoController.choiceInputTargets.forEach( function(node) {
        node.parentElement.style.opacity = 0
      })
    })
  }

  validateContinue() {
    if (this.choiceInputTargets.some(el => el.checked)){
      this.enableContinue()
      this.enabledChoice()
    } else {
      this.disableContinue()
      this.disableChoice()
    }
  }

  enableContinue() {
    this.continueBtn.removeAttribute('disabled')
    this.continueBtn.classList.remove('disabled')
  }

  disableContinue() {
    this.continueBtn.classList.add('disabled')
    this.continueBtn.setAttribute('disabled', true)
  }

  disableChoice() {
    this.$popovers.popover('disable')
    this.choiceInputTargets.forEach( function(node) {
      node.setAttribute('disabled', true)
      node.classList.add('disabled')
    })
  }

  enabledChoice() {
    this.$popovers.popover('enable')
    this.choiceInputTargets.forEach( function(node) {
      node.classList.remove('disabled')
      node.removeAttribute('disabled')
    })
  }
}