import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {

  initialize() {
    // TODO: setup assign value in this controlller
  }

  connect() {
    $('.custom-control-input').on('click', function(e) {
      $('.continue-btn').find('input[name="answer"]').val(e.target.value)
    })

  }

}