import { Controller } from "stimulus"
import Player from '@vimeo/player';

export default class extends Controller {

  static targets = [ "playBtn", "videoDiv" ]

  connect() {
    this.continueBtn = document.querySelector('.btn-continue')
    this.loadVideoPlayer()
    this.playBtnTarget.classList.add('card-active')
    this.playBtnTarget.dataset.played = "true"
    // this.stageProgress != 'completed' && this.setDisabled()
  }

  get stageProgress() {
    return this.data.get('progress')
  }

  // Button States
  //////////////////
  setDisabled() {
    this.disableContinue()
    this.playBtnTargets.forEach( function(node) {
      node.dataset.played != "true" && node.classList.add('card-disabled')
    })
  }

  enableNextButton() {
    this.trackContinue()
    let nextButton = document.querySelector('.card-active').parentElement.nextElementSibling
    nextButton = nextButton.querySelector('.card-video-order')
    nextButton.classList.remove('card-disabled')
  }

  activateButton() {
    this.clearActive()
    event.currentTarget.classList.add('card-active')
    let newVideoID = event.currentTarget.dataset.videoid
    this.newVideo(newVideoID)
  }

  clearActive() {
    this.playBtnTargets.forEach( function(node) {
      node.classList.remove('card-active')
    })
  }

  // Load Video Players
  //////////////////
  loadVideoPlayer(newVideoID) {

    let videoID = this.playBtnTarget.dataset.videoid
    let options = { id: videoID, width: 600 }
    let player = new Player('video-player', options)

    let videoController = this
    player.on('ended', function() {
      videoController.enableNextButton()
    });
    player.on('ended', function() {
      console.log('ended');
    });

  }

  newVideo(newVideoID) {
    // build new player instance
    let iframe = this.videoDivTarget.querySelector('iframe')
    let player = new Player(iframe);

    let vimeoID = newVideoID
    player.loadVideo(vimeoID).catch( function(error) {
      console.log(error)
    })
  }

  trackContinue() {
    if (this.playBtnTargets.every(this.checkForPlay)) {
      this.enableContinue()
    } else {
      this.disableContinue()
    }
  }

  checkForPlay(element) {
    return !element.classList.contains('card-disabled')
  }

  enableContinue() {
    this.continueBtn.removeAttribute('disabled')
    this.continueBtn.classList.remove('disabled')
  }

  disableContinue() {
    this.continueBtn.classList.add('disabled')
    this.continueBtn.setAttribute('disabled', true)
  }

}