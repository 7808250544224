import { Controller } from "stimulus"
import Player from '@vimeo/player';

export default class extends Controller {

  static targets = [ "playBtn", "thumbnail" ]

  connect() {
    this.continueBtn = document.querySelector('.btn-continue')
    this.disableContinue(this.continueBtn)
    this.getThumbnails()
  }

  getThumbnails() {
    this.thumbnailTargets.forEach( function(node) {
      let id = node.dataset.videoid
      $.getJSON('https://vimeo.com/api/oembed.json?url=https://vimeo.com/' + id, {format: "json"}, function(data) {
        node.setAttribute('src', data.thumbnail_url)
      });
    })
  }

  playVideo(event) {
    this.openModal(event.currentTarget.dataset.modal)
    this.setPlayed(event.currentTarget)
    this.trackContinue()
  }

  openModal(modal) {
    let modalID = "#" + modal

    //build new player instance
    let iframe = $(modalID).find('iframe')[0]
    let newPlayer = "player_" + modal
    newPlayer = new Player(iframe);

    this.closeButton = $(modalID)[0].querySelector('[data-dismiss="modal"]')
    const videoController = this

    // stop player when modal closed
    $(modalID).on('show.bs.modal', function (e) {
      videoController.disableContinue(videoController.closeButton)
    })

    $(modalID).on('hidden.bs.modal', function (e) {
      newPlayer.pause()
    })

    $(modalID).modal({keyboard: false, backdrop: 'static'})

    newPlayer.on('ended', function() {
      videoController.enableContinue(videoController.closeButton)
    });

  }

  setPlayed(target) {
    let activeBtn = target
    activeBtn.classList.add('btn-checked')
    activeBtn.innerHTML = ''
    activeBtn.classList.remove('btn-play')
  }

  trackContinue() {
    if (this.playBtnTargets.every(this.checkForPlay)) {
      this.enableContinue(this.continueBtn)
    } else {
      this.disableContinue(this.continueBtn)
    }
  }

  checkForPlay(element) {
    return element.classList.contains('btn-checked')
  }

  disableContinue(btnElm) {
    btnElm.classList.add('disabled')
    btnElm.setAttribute('disabled', true)
  }

  enableContinue(btnElm) {
    btnElm.removeAttribute('disabled')
    btnElm.classList.remove('disabled')
  }

}