import { Controller } from "stimulus"
import $ from 'jquery'
import slick from 'slick-carousel'

export default class extends Controller {

  static targets = [ "comics", "answerInput", "answers",
                    "answerControl", "response", "considerBtn",
                    "displayResponse"]

  initialize() {
    this.clickContinue = this.clickContinue.bind(this)
    this.continueBtn = document.querySelector('.btn-continue')
    this.submitForm = document.querySelector('form.continue-btn .btn-continue')
    this.continueBtn.addEventListener('click', this.clickContinue, false)
    this.hideSubmit()
  }

  connect() {
    this.setID()
    this.validateContinue()
  }

  get answerID() {
    return parseInt(this.data.get('id'))
  }

  setID(event) {
    let checked = document.querySelector('input:checked')
    checked && this.data.set('id', checked.dataset.id)
    this.validateContinue()
  }

  clickContinue() {
    this.hideResponses()
    this.hideAnswersDiv()
    this.displaySubmit()
    this.continueBtn.classList.add('d-none')
    let responseID = 'answers' + this.answerID
    let showResponse = document.querySelector("[data-id=" + responseID + "]")
    showResponse.classList.remove('d-none')
  }

  toggleAnother(event) {
    event.preventDefault()
    this.hideResponses()
    this.resetConsideration()
    this.hideSubmit()
    this.continueBtn.classList.remove('d-none')
    this.comicsTarget.classList.remove('d-none')
    this.comicsTarget.classList.add('d-flex')
    this.answersTarget.classList.remove('h-0')
    this.answerControlTargets.forEach(elm => elm.classList.remove('opacity-0'))
  }

  toggleConsideration() {
    let consideration = event.currentTarget.parentElement.nextElementSibling
    event.currentTarget.classList.add('opacity-0')
    consideration.classList.remove('d-none')
    this.enableContinue( this.submitForm )
  }

  resetConsideration() {
    this.considerBtnTargets.forEach(function(elm) {
      elm.classList.remove('opacity-0')
      let considerCard = elm.parentElement.nextElementSibling
      considerCard.classList.add('d-none')
    })
  }

  hideAnswersDiv() {
    this.comicsTarget.classList.remove('d-flex')
    this.comicsTarget.classList.add('d-none')
    this.answerControlTargets.forEach(elm  => elm.classList.add('opacity-0'))
    this.answersTarget.classList.add('h-0')
  }

  hideResponses() {
    this.responseTargets.forEach(elm  => elm.classList.add('d-none'))
  }

  validateContinue() {
    if (this.answerInputTargets.some(el => el.checked)){
      this.enableContinue(this.continueBtn)
    } else {
      this.disableContinue(this.continueBtn)
    }
  }

  hideSubmit() {
    this.submitForm.classList.add('d-none')
  }

  displaySubmit() {
    this.submitForm.classList.remove('d-none')
    this.disableContinue( this.submitForm )
  }

  disableContinue(btnElm) {
    btnElm.classList.add('disabled')
    btnElm.setAttribute('disabled', true)
  }

  enableContinue(btnElm) {
    btnElm.removeAttribute('disabled')
    btnElm.classList.remove('disabled')
  }
}