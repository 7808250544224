import { Controller } from "stimulus"
import $ from 'jquery'
import slick from 'slick-carousel'

export default class extends Controller {

  static targets = [ "comics", "answerInput", "answers",
                    "answerControl", "response", "considerBtn"]

  connect() {
    this.modalForImages()
  }

  modalForImages() {
    $('#scenario-image').on('show.bs.modal', function (event) {
      const modal = this
      let currentImg  = event.relatedTarget.querySelector('img')
      let path        = currentImg.getAttribute('src')
      const modalBody = modal.querySelector('.modal-body')

      let newImage    = !modalBody.querySelector('img') ? document.createElement('img') : modalBody.querySelector('img')
      newImage.setAttribute('src', path)
      newImage.setAttribute('class', 'img-fluid')
      !modal.querySelector('img') && modalBody.appendChild(newImage)

    })
  }

}