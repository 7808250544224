import { Controller } from "stimulus"
import Player from '@vimeo/player';

export default class extends Controller {

  connect() {
    this.continueBtn = document.querySelector('[data-dismiss="modal"]')

    this.playIntro()
    this.disableContinue()

    var iframe = document.querySelector('iframe');
    var player = new Player(iframe);
    let videoController = this

    player.on('ended', function() {
      videoController.enableContinue()
    })
  }

  get userID() {
    return this.data.get('userid')
  }

  get viewIntro() {
    return this.data.get('viewIntro')
  }

  playIntro() {
    if (this.viewIntro == 'false') {
      $('#introVideo').modal({
        backdrop: 'static'
      })
    }
  }

  closeIntro() {
    const userID = this.userID
    $.ajax({
      url: '/users/'+ userID + '/complete-intro',
      method: 'PUT'
    })
  }

  enableContinue() {
    this.continueBtn.removeAttribute('disabled')
    this.continueBtn.classList.remove('disabled')
  }

  disableContinue() {
    this.continueBtn.classList.add('disabled')
    this.continueBtn.setAttribute('disabled', true)
  }

}
